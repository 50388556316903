//provider
export const getProvidersSelector = (state) => state.providers;

//business
export const selectAllBrandsDependsOnBusinessGroup = (state) => state.business?.allBrandsDependsOnBusinessGroup;
export const selectSelectedBrands = (state) => state.business?.selectedBrands;
export const selectUnSelect = (state) => state.business?.unSelect;
export const selectSelectedLocations = (state) => state.business?.selectedLocations;
export const selectLocationIdsForReport = (state) => state.business?.locationIdsForReport;
export const selectAllLocationsDependsOnBusinessGroup = (state) => state.business?.allLocationsDependsOnBusinessGroup;
export const selectAllFilterBrandList = (state) => state.business?.filterBrandList;
export const selectAllLocations = (state) => state.business?.allLocations;
export const selectAllBrandIdsForReport = (state) => state.business?.brandIdsForReport;
export const selectAllBusinessGroups = (state) => state.business?.allBusinessGroups;

//report
export const selectFilterListReport = (state) => state.reports?.filterList;

//user
export const selectUserBusinessListReport = (state) => state.user?.data?.businessList;
