import { memo } from 'react';
import { Box, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  StyledShowMoreLessButton,
  StyledShowMoreLessLink,
  StyledShowMoreLessText
} from 'components/Order/OrderStatusChange/styles';
import useDeliveryDispatchDetails from 'components/Order/OrderDeliverDetails/hooks/useDeliveryDispatchDetails';

const DispatchDeliveryDetails = ({ orderId, deliveryData }) => {
  const { t } = useTranslation();
  const {
    btnName,
    loadingDeliveryDetails,
    deliveryDetailsData,
    handleClick,
    showDetails,
    handleCloseDetails,
    currentBusiness
  } = useDeliveryDispatchDetails(orderId);

  return (
    <>
      <Collapse in={showDetails}>
        <Box>
          <StyledShowMoreLessText>
            {t('Delivered by')} {deliveryDetailsData?.providerName}
          </StyledShowMoreLessText>
          <StyledShowMoreLessText>
            {t('Phone Number:')} {deliveryDetailsData?.phoneNumber}
          </StyledShowMoreLessText>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <StyledShowMoreLessText>
              {t('Tracking URL:')}
            </StyledShowMoreLessText>
              <StyledShowMoreLessLink
                href={`${process.env.REACT_APP_TRACK_URL}/?oid=${deliveryData?._id}&bid=${currentBusiness?._id}&tr=${deliveryDetailsData?.trackingUrl || ''}`}
                target={'_blank'}>Link</StyledShowMoreLessLink>
          </Box>
          <StyledShowMoreLessText>
            {t('Ref. Code')}: {deliveryDetailsData?.refCode || '-'}
          </StyledShowMoreLessText>
        </Box>
      </Collapse>
      <StyledShowMoreLessButton
        sx={{ mt: showDetails ? 2 : 0 }}
        variant="outlined"
        disabled={loadingDeliveryDetails}
        loading={loadingDeliveryDetails}
        onClick={showDetails ? handleCloseDetails : handleClick}
      >
        {btnName}
      </StyledShowMoreLessButton>
    </>
  );
};

export default memo(DispatchDeliveryDetails);
