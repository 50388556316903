import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectCurrentBusiness } from 'components/Providers/ProviderList';
import { DISPATCH_BTN_ENUM } from 'components/Order/OrderDeliverDetails/hooks/useDeliveryDispatchDetails';
import { StyledShowMoreLessButton, StyledShowMoreLessLink, StyledShowMoreLessText } from '../OrderStatusChange/styles';


const ProviderDeliveryDetails = ({ deliveryData, providers }) => {
  const { t } = useTranslation();
  const currentBusiness = useSelector(selectCurrentBusiness);
  const [showDetails, setShowDetails] = useState(false);
  const handleClick = () => setShowDetails(prevState => !prevState);

  const trackingURL = useMemo(() => {
    return deliveryData?.delivery?.trackingUrl || deliveryData?.metadata?.trackingUrl || '';
  }, [deliveryData?.delivery?.trackingUrl, deliveryData?.metadata?.trackingUrl])

  return (
    <>
      <Collapse in={showDetails}>
        <Box>
          <StyledShowMoreLessText>
            {t('Delivered by')}{' '}
            {deliveryData.delivery?.deliveryCarrier === 'self' ? (
              t('website_settings.in_house_delivery').toUpperCase()
            ) : (
              <>
                {deliveryData?.provider?.toUpperCase() === providers.food2.srv
                  ? deliveryData?.provider?.toUpperCase()
                  : deliveryData?.delivery?.deliveryCarrier?.toUpperCase()}
              </>
            )}
          </StyledShowMoreLessText>
          <StyledShowMoreLessText>
            {t('Phone Number:')} {deliveryData?.phoneNumber || deliveryData?.phone || '-'}
          </StyledShowMoreLessText>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <StyledShowMoreLessText>
              {t('Tracking URL:')}
            </StyledShowMoreLessText>
              <StyledShowMoreLessLink
                href={`${process.env.REACT_APP_TRACK_URL}/?oid=${deliveryData?._id}&bid=${currentBusiness?._id}&tr=${trackingURL}`}
                target={'_blank'}>Link</StyledShowMoreLessLink>
          </Box>
          <StyledShowMoreLessText>
            {t('Ref. Code')}: {deliveryData?.referenceCode || '-'}
          </StyledShowMoreLessText>
        </Box>
      </Collapse>
      <StyledShowMoreLessButton
        sx={{ mt: showDetails ? 2 : 0 }}
        variant="outlined"
        onClick={handleClick}
      >
        {showDetails
          ? DISPATCH_BTN_ENUM.SHOW_LESS
          : DISPATCH_BTN_ENUM.SHOW_MORE}
      </StyledShowMoreLessButton>
    </>
  )
}

export default memo(ProviderDeliveryDetails);